<template lang="pug">
div.mt-3
  div(v-if="$vuetify.breakpoint.smAndUp").mt-n5
    v-card(
      style="border-bottom: 3px solid #010073",
      width="175",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Frequently Used
    br
    v-sheet.mt-15.pt-1.rounded-lg.mx-auto(elevation="2", max-width="1200")
      v-slide-group.mt-n15(center-active, show-arrows, width="1200")
        v-slide-item(v-for="book in books", :key="book.id")
            v-tooltip(bottom color="tertiary" max-width="300" nudge-top="40")
              template(v-slot:activator="{ on, attrs }")
                v-card.px-5.mt-15.mx-2.justify-center(
                  height="235px",
                  width="170px",
                  elevation="0",
                  v-bind="attrs",
                  v-on="on"
                  @click="link(book.id)"
                ) 
                  v-row.d-flex.justify-content-center
                    v-img.float.mt-n12(
                      :src="book.imageUrl",
                      lazy-src="@/assets/cover.png" 
                      max-width="110px",
                      height="160px",
                      style="object-fit: cover"
                    )
                      template(v-slot:placeholder)
                        v-row(
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        )
                          v-progress-circular(
                          indeterminate
                          color="grey lighten-5")

                  v-list-item-group
                    v-list-item-content.mx-1
                      v-list-item-title.body-1.font-weight-regular {{ book.title }}
                      v-list-item-title.mt-n1.body-2.text--secondary.font-weight-light(
                        v-bind="attrs",
                        v-on="on"
                      ) by &nbsp {{book.author[0]}}
              div.black--text
                div {{ book.title }}
                div.caption.font-weight-light
                  span(v-if="book.author.length == 1") by {{ book.author[0] }}
                  span(v-else) by {{ book.author[0] }} <span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }}
                div.caption.font-weight-light {{book.publisher}} • {{book.year}}

    br
    br
    v-card(
      style="border-bottom: 3px solid #010073",
      width="100",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Subjects
    br
    v-sheet.mx-auto.mt-15.pt-1.rounded-lg(elevation="2", max-width="1200")
      v-slide-group.mt-n15(
        v-model="model",
        class="",
        center-active,
        show-arrows,
        width="1200"
      )
        v-slide-item(v-for="subject in subjects", :key="subject.name")
          router-link(:to="{ path: '/user/subject/' + subject.name }", replace)
            v-card.px-5.mt-15.mx-2(
              height="215px",
              width="170px",
              elevation="0",
              color="transparent"
            ) 
              v-row.d-flex.justify-content-center
                img.mt-n12.float(
                  :src="require(`@/assets/subjects/${subject.image}`)",
                  height="160px"
                  max-width="110px"
                  )
                h6.text-center.font-weight-regular.mt-2(style="color: black") {{ subject.name }}

    br
    br
    v-card(
      style="border-bottom: 3px solid #010073",
      width="200",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Syllabi References
    br
    v-sheet.mx-auto.mt-15.pt-1.rounded-lg(elevation="2", max-width="1200")
      v-slide-group.mt-n15(
        v-model="institutes",
        center-active,
        show-arrows,
        width="1200"
      )
        v-slide-item(v-for="i in institutes", :key="i.id")
          router-link(:to="{ path: '/user/syllabi/' + i.name }")
            v-card.px-5.mt-15.mx-2.rounded-circle(
              width="170px",
              height="200px",
              elevation="0",
            ) 
              v-row.d-flex.justify-content-center                
                img.mt-n12.rounded-circle.float(
                  :src="require(`@/assets/institutes/${i.image}`)",
                  height="110px"
                  )

              v-list-item-group
                v-list-item-content
                  h6.text-center.font-weight-regular(style="color: black") {{ i.name }}

  div(v-else).mt-n6
    v-card(
      style="border-bottom: 3px solid #010073",
      width="175",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Frequently Used
    v-card.pt-5(v-if="books == ''").mt-3
    v-card(v-else).mt-3.pa-5
      vue-horizontal
        section(v-for="book in books" :key="book.id").mx-2
          v-img(
          :src="book.imageUrl",
          :lazy-src="book.imageUrl"
          max-width="110px",
          height="160px",
          style="object-fit: cover"
           @click="link(book.id)"
          ).mx-auto
            template(v-slot:placeholder)
              img(src="@/assets/cover.png")
          v-list-item-group
            v-list-item-content.mx-1
              v-list-item-title.body-1
                strong {{ book.title }}
              v-list-item-title.mt-n1.body-2.text--secondary by &nbsp
                span(
                  v-if="book.author.length == 1",
                  v-for="item in book.author"
                ) {{ item }}
                span(v-else, v-for="item in book.author") {{ item }}, &nbsp
    br
    v-card(
      style="border-bottom: 3px solid #010073",
      width="100",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Subjects
    v-card.pa-5.mt-3
      vue-horizontal
        section(v-for="subject in subjects", :key="subject.name").mx-2
          v-img(
          :src="require(`@/assets/subjects/${subject.image}`)",
          height="160px",
          max-width="110px",
          style="object-fit: cover"
          @click="linkCategory('subject', subject.name)"
          ).mx-auto
          h6.text-center.font-weight-medium.mt-2(style="color: black") {{ subject.name }}

    br
    v-card(
      style="border-bottom: 3px solid #010073",
      width="200",
      color="#f3f3f3",
      tile,
      flat
    )
      h4.font-weight-light Syllabi References
    v-card.pa-5.mt-3
      vue-horizontal
        section(v-for="i in institutes",,  :key="i.id").mx-2
          v-img.mx-auto(
            :src="require(`@/assets/institutes/${i.image}`)",
            height="110px"
            width="110px"
            @click="linkCategory('syllabi', i.name)"
          )
          h6.text-center.font-weight-medium.mt-2(style="color: black") {{ i.acronym }}

  v-overlay(opacity=".9" color="#f3f3f3" v-if="books==''").pl-lg-10
    v-progress-circular.ml-lg-15(
      :size="70"
      :width="7"
      color="primary"
      indeterminate)
  br

</template>

<script>
import syllabi from "@/syllabi.js";
import subject from "@/subjects.js";
import { db } from "../main";
import VueHorizontal from "vue-horizontal";
export default {
  components: { VueHorizontal },
  data() {
    return {
      model: null,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      institutes: syllabi.institutes,
      subjects: subject.subjects,
      books: [],
      options: {
        responsive: [{ size: 2 }],
        navigation: { start: 1, color: "#000" },
      },
    };
  },
  async created() {
    // await this.$bind(
    //   "books",
    //   db
    //     .collection("books")
    //     .orderBy("views", "desc")
    //     .limit(10)
    // );
    if (sessionStorage.books) {
      this.books = JSON.parse(sessionStorage.getItem("books"));
    } else {
      const dataBase = await db
        .collection("books")
        .orderBy("views", "desc")
        .limit(15);
      const dbResults = await dataBase.get();
      var books = [];
      dbResults.forEach((doc) => {
        const data = {
          id: doc.id,
          imageUrl: doc.data().imageUrl,
          author: doc.data().author,
          title: doc.data().title,
          publisher: doc.data().publisher,
          year: doc.data().year,
        };
        books.push(data);
      });
      this.books = books;
      sessionStorage.setItem("books", JSON.stringify(books));
    }
  },
  methods: {
    link: function (id) {
      this.$router.push(`./book-item/${id}`);
    },
    linkCategory: function (category, slug) {
      this.$router.push(`../user/${category}/${slug}`);
    },
  },
};
</script>

<style>
.head-text {
  color: #010073;
}

.float:hover,
.float:focus {
  box-shadow: 0 1em 1em -0.4em gray;
  transform: translateY(-0.25em);
}
section {
  width: calc((100% - (10px)) / 2);
}
</style>
